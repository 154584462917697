export function getDefaultEventParams() {
  let sessionId = localStorage.getItem("sessionId");

  if (!sessionId) {
    sessionId = getRandomSessionId();
    localStorage.setItem("sessionId", sessionId);
  }

  return {
    timestamp: new Date(),
    sessionId: sessionId,
  };
}

function getRandomSessionId() {
  return (
    Math.random().toString(36).substring(7) +
    Math.random().toString(36).substring(7) +
    Math.random().toString(36).substring(7)
  );
}
