import { useEffect } from "react";
import { useLivePreviewWidgetSettings } from "../hooks/useLivePreviewWidgetSettings";
import {
  useContacts,
  useSendEvent,
  useWidgetSettings,
} from "../hooks/useWidgetSettings";
import { Widget } from "./Widget";

interface WidgetDataLoaderProps {
  shopName: string;
  isDevelopmentMode: boolean;
}

type Data = { data: Record<string, string | boolean | number> };
type Error = unknown;

export function WidgetDataLoader({
  isDevelopmentMode,
  shopName,
}: WidgetDataLoaderProps) {
  const { data: developmentData } = useLivePreviewWidgetSettings<Data>();
  const { data } = useWidgetSettings<Data, Error>(shopName);
  const {
    data: contactsData,
    loading,
    error,
  } = useContacts<any, any>(shopName);
  const sendEvent = useSendEvent();

  useEffect(() => {
    if (isDevelopmentMode) return;
    sendEvent("VIEW_WIDGET", shopName);
  }, []);

  function handleClickEvent() {
    if (isDevelopmentMode) return;

    sendEvent("CLICK_WIDGET", shopName);
  }

  if (loading) return null;

  if (!isDevelopmentMode && data) {
    return (
      <Widget
        onClick={handleClickEvent}
        contacts={contactsData?.data}
        data={data.data}
      />
    );
  }

  if (isDevelopmentMode && developmentData?.data) {
    return (
      <Widget
        onClick={handleClickEvent}
        contacts={contactsData?.data}
        data={developmentData.data}
      />
    );
  }

  return null;
}
